import React, { useState, useContext } from 'react';
import Navbar from '../components/Navbar.js';
import { UserContext } from '../components/UserContext.js';
import '../css/mbpage.css';

const MBPage = () => {
  const { userId } = useContext(UserContext); 
  const [selectedOption, setSelectedOption] = useState('pregame');

  return (
    <div>
      <Navbar />
      <div className="main-container">
        {userId === null ? (
          <div className="login-message">
            <h2>Inicia sesión para acceder al contenido</h2>
          </div>
        ) : (
          <div className="content">
            <h1>Arbitraje</h1>
            <p>Aprovecha los errores que comenten las casas en sus cuotas de apuestas.</p>
            <div className="selector">
                <button 
                  className={`selector-button ${selectedOption === 'pregame' ? 'active' : ''}`}
                  onClick={() => setSelectedOption('pregame')}
                >
                  Pre-match
                </button>
                <button 
                  className={`selector-button ${selectedOption === 'live' ? 'active' : ''}`}
                  onClick={() => setSelectedOption('live')}
                >
                  Live
                </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MBPage;