import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import { UserContext } from '../components/UserContext.js'; // Importa el UserContext

const Register = () => {
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(''); // Estado para manejar errores
    const { setUserId } = useContext(UserContext); // Accede a setUserId desde el contexto
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Validación simple para asegurarse de que las contraseñas coincidan
        if (password !== confirmPassword) {
            setError("Las contraseñas no coinciden");
            return;
        }

        try {
            const response = await fetch('https://panda-bet-back.vercel.app/users/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    lastname, // Incluye el apellido en la solicitud
                    email,
                    password,
                    admin: false,
                }),
            });

            if (!response.ok) {
                throw new Error('Error en el registro');
            }

            const data = await response.json();
            const { id } = data;
            console.log(data);

            if (id) {
                alert("Usuario registrado correctamente");
                setUserId(id); // Establece el userId en el contexto
                navigate('/');
            } else {
                throw new Error("No se recibió un ID de usuario.");
            }
        } catch (error) {
            setError(error.message);
            console.error(error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className="main-container">
                <h2>Regístrate</h2>
                {error && <p className="error-message">{error}</p>}
                <form className="signin-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            placeholder='Nombre'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="lastname"
                            placeholder='Apellidos'
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            placeholder='Correo Electrónico'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            id="password"
                            placeholder='Contraseña'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            id="confirmPassword"
                            placeholder='Repite tu contraseña'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="submit-button">Registrarse</button>
                </form>
            </div>
        </div>
    );
};

export default Register;