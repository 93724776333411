import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import { UserContext } from '../components/UserContext.js';
import '../css/account.css'; 

const Account = () => {
    const [user, setUser] = useState(null);
    const { userId, setUserId } = useContext(UserContext); // Simplificación del uso de UserContext
    const navigate = useNavigate(); 

    const handleLogOut = () => {    
        setUserId(null); // Limpia el userId en el contexto
        navigate('/signIn'); 
    };

    useEffect(() => {
        if (userId) {
            // Fetch user data from backend
            fetch(`https://panda-bet-back.vercel.app/users/${userId}`)
                .then(response => response.json())
                .then(data => setUser(data))
                .catch(error => console.error(error));
        }
    }, [userId]);

    if (!user) {
        return (
            <div>
                <Navbar />
                <div className="main-container-account">
                    <h2>Cargando...</h2>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <div className="main-container-account">
                <h2>Mi Cuenta</h2>
                <div className="account-info">
                    <p><strong>Nombre:</strong> {user.name}</p>
                    <p><strong>Correo Electrónico:</strong> {user.email}</p>
                    <p>
                    <strong>
                        Suscripción: {user.sub === 0 ? "No suscrito" : user.sub === 1 ? "Suscrito mensualmente" : "Suscrito anualmente"}
                    </strong>
                    </p>
                </div>
                <button className="logout-button" onClick={handleLogOut}>Cerrar Sesión</button>
                <button className="delete-account-button">Cambiar Contraseña</button>
            </div>
        </div>
    );
};

export default Account;