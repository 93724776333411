import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import { UserContext } from '../components/UserContext.js';
import '../css/mbpage.css';

const MBPage = () => {
  const { userId } = useContext(UserContext); // Obtén el userId del contexto
  const [selectedOption, setSelectedOption] = useState('bienvenida');
  const [promosRec, setPromosRec] = useState([]);
  const [promosBie, setPromosBie] = useState([]);
  const navigate = useNavigate();  // Hook para la navegación

  // useEffect para cargar los datos de las promociones al montar el componente
  useEffect(() => {
    const fetchPromos = async () => {
      try {
        const response = await fetch('https://panda-bet-back.vercel.app/promos/');
        const data = await response.json();
        // Filtrar las promos recurrentes y de bienvenida
        const recurrentPromos = data.filter(promo => promo.recurrent);
        const bienvenidaPromos = data.filter(promo => !promo.recurrent);
        setPromosRec(recurrentPromos);
        setPromosBie(bienvenidaPromos);
      } catch (error) {
        console.error('Error al obtener las promociones:', error);
      }
    };

    fetchPromos();
  }, []); // El array vacío asegura que esto solo se ejecute al montar el componente

  const handlePromoClick = (promoId) => {
    // Navega a la página de la promoción específica usando su ID
    navigate(`/mbetting/promo/${promoId}`);
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'bienvenida':
        return (
          <div className="content">
            <h2>Bienvenida</h2>
            <p>¡Bienvenido a nuestro sitio de Matched Betting! Aquí aprenderás cómo maximizar tus ganancias.</p>
            {promosBie.length > 0 ? (
              <ul className="promo-list">
                {promosBie.map((promo) => (
                  <li 
                    key={promo._id} 
                    className="promo-item" 
                    onClick={() => handlePromoClick(promo._id)}  // Añade el evento de clic
                  >
                    <span className="promo-name">{promo.name}</span>
                    <span className="promo-earning">Ganancia estimada: {promo.earning}€</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Cargando...</p>
            )}
          </div>
        );
      case 'recurrente':
        return (
          <div className="content">
            <h2>Recurrente</h2>
            <p>Contenido recurrente: sigue nuestras estrategias avanzadas para obtener beneficios continuos.</p>
            {promosRec.length > 0 ? (
              <ul className="promo-list">
                {promosRec.map((promo) => (
                  <li 
                    key={promo._id} 
                    className="promo-item" 
                    onClick={() => handlePromoClick(promo._id)}  // Añade el evento de clic
                  >
                    <span className="promo-name">{promo.name}</span>
                    <span className="promo-earning">Ganancia estimada: {promo.earning}€</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No hay promociones recurrentes disponibles en este momento.</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Navbar /> 
      <div className="main-container">
        {userId !== null ? (
          <>
            <h1>Matched Betting</h1>
            <p>Sacale beneficio asegurado a las casas de apuestas</p>
        <div className="selector">
          <button 
            className={`selector-button ${selectedOption === 'bienvenida' ? 'active' : ''}`}
            onClick={() => setSelectedOption('bienvenida')}
          >
            Bienvenida
          </button>
          <button 
            className={`selector-button ${selectedOption === 'recurrente' ? 'active' : ''}`}
            onClick={() => setSelectedOption('recurrente')}
          >
            Recurrente
          </button>
        </div>

        <div className="content-container">
          {renderContent()}
        </div>
        </>
        ) : ( 
          <div>
            <div className="login-message">
              <h2>Inicia sesión para acceder al contenido</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MBPage;