import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import '../css/signin.css';
import { UserContext } from '../components/UserContext.js'; // Importa el UserContext

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Estado para manejar errores
  const navigate = useNavigate();
  const { setUserId } = useContext(UserContext); // Accede a setUserId desde el contexto

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validación básica del correo electrónico
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError('Por favor, introduce un correo electrónico válido.');
      return;
    }

    try {
      const response = await fetch('https://panda-bet-back.vercel.app/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (!response.ok) {
        throw new Error('Error en la autenticación');
      }

      const data = await response.json();

      if (data.id) {
        setUserId(data.id); // Establece el userId en el contexto
        navigate('/'); // Navega a la página de inicio o donde prefieras
      } else {
        setError('Inicio de sesión fallido. Verifica tu correo electrónico y contraseña.');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="main-container">
        <h2>Accede a tu cuenta</h2>
        {error && <p className="error-message">Ha ocurrido un error en la autenticación</p>}
        <form onSubmit={handleSubmit} className="signin-form">
          <div className="form-group">
            <input
              type="email"
              id="email"
              value={email}
              placeholder='Correo Electrónico'
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              placeholder='Contraseña'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">Iniciar Sesión</button>
          <a className="create-account" href='/register'>Darme de alta</a>
        </form>
      </div>
    </div>
  );
};

export default SignIn;