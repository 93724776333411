import React, { createContext, useState, useEffect } from 'react';

// Crea el contexto
export const UserContext = createContext();

// Proveedor del contexto
export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null); // Inicialmente null

  // Cargar el userId desde localStorage cuando la app se inicia
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId); // Cargar directamente como string
    }
  }, []);

  // Guardar el userId en localStorage cada vez que cambia
  useEffect(() => {
    if (userId !== null) {
      localStorage.setItem('userId', userId); // Guardar directamente como string
    } else {
      localStorage.removeItem('userId'); // Si es null, eliminar de localStorage
    }
  }, [userId]); // Se ejecuta cada vez que userId cambia

  return (
    <UserContext.Provider value={{ userId, setUserId }}>
      {children}
    </UserContext.Provider>
  );
};