import React from 'react';
import Navbar from '../components/Navbar.js';
import '../css/aboutus.css';  // Asegúrate de que este archivo existe

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <div className="main-container">
        <h1>¿Qué es PandaBet?</h1>
        <p>
          Bienvenido a <strong>PandaBet</strong>, la plataforma líder para quienes buscan aprovechar al máximo el mundo de las apuestas deportivas a través del <strong>matched betting</strong> y el <strong>arbitraje de apuestas</strong>. Nuestro objetivo es ayudarte a generar ingresos consistentes y seguros, eliminando el riesgo asociado con las apuestas tradicionales.
        </p>

        <div className="content-columns">
          <div className="column">
            <h2>¿Qué es el Matched Betting?</h2>
            <p>
              El <strong>matched betting</strong> es una estrategia que utiliza las ofertas promocionales de las casas de apuestas para garantizar ganancias sin riesgo. Al cubrir todas las posibles salidas de un evento deportivo utilizando apuestas gratuitas y apuestas opuestas en una casa de intercambio, puedes asegurar un beneficio sin importar el resultado del evento.
            </p>
            <p>
              En <strong>PandaBet</strong>, te proporcionamos todas las herramientas y recursos necesarios para que puedas empezar a ganar desde el primer día.
            </p>
          </div>

          <div className="column">
            <h2>¿Qué es el Arbitraje de Apuestas?</h2>
            <p>
              El <strong>arbitraje de apuestas</strong> o "sure betting" es una técnica que implica aprovechar las discrepancias en las cuotas ofrecidas por diferentes casas de apuestas. Al apostar en todos los resultados posibles de un evento deportivo en varias casas de apuestas, puedes asegurar una ganancia sin importar el resultado final.
            </p>
            <p>
              Esta estrategia requiere precisión y rapidez, y en <strong>PandaBet</strong> te proporcionamos las alertas y herramientas necesarias para que no te pierdas ninguna oportunidad de arbitraje.
            </p>
          </div>
        </div>

        <h2>Nuestra Misión</h2>
        <p>
          En <strong>PandaBet</strong>, creemos que las apuestas deportivas pueden ser una fuente de ingresos confiable y consistente cuando se hacen de manera estratégica y bien informada. Nuestro equipo está compuesto por expertos en la industria que se dedican a investigar, desarrollar y ofrecer las mejores estrategias y herramientas para que nuestros usuarios puedan maximizar sus ganancias de forma segura.
        </p>

        <h2>¿Por Qué Elegir PandaBet?</h2>
        <ul>
          <li><strong>Herramientas Avanzadas:</strong> Nuestro software escanea constantemente las casas de apuestas y te notifica cuando surgen oportunidades de matched betting o arbitraje.</li>
          <li><strong>Soporte Dedicado:</strong> Nuestro equipo de soporte está siempre disponible para ayudarte en cada paso del camino, asegurando que puedas aprovechar al máximo nuestras herramientas y estrategias.</li>
          <li><strong>Educación Continua:</strong> Ofrecemos guías, tutoriales y actualizaciones constantes para mantenerte informado sobre las mejores prácticas y nuevas oportunidades en el mundo del matched betting y arbitraje.</li>
          <li><strong>Comunidad:</strong> Únete a una comunidad de apostadores con ideas afines, donde puedes compartir estrategias, consejos y experiencias.</li>
        </ul>

        <h2>Únete a Nosotros</h2>
        <p>
          Si estás listo para convertir las apuestas deportivas en una fuente de ingresos fiable, <strong>PandaBet</strong> es tu mejor opción. Regístrate hoy y empieza a aprovechar las oportunidades que el matched betting y el arbitraje de apuestas tienen para ofrecerte.
        </p>

        <p>¡Estamos aquí para ayudarte a ganar!</p>

    
      </div>
      <footer className="footer">
          <p>&copy; 2024 PandaBet. Todos los derechos reservados.</p>
        </footer>
    </div>
  );
};

export default AboutUs;
