import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../components/Navbar.js';
import { UserContext } from '../components/UserContext.js';
import '../css/calculator.css';

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .catch(err => console.error('Error al copiar el texto: ', err));
};

const calcularResultado = (cantidad, cuota1, cuota2, tipo, comision, clase, reem) => {
    let resultado = 0;
    let cantidad2 = 0;
    let riesgo = 0.00;
    if (cantidad !== 0 && cuota1 !== 0 && cuota2 !== 0) {
        if (clase === 0){ // Dinero Real
            if (tipo === 1) {
                cantidad2 = cantidad * cuota1 / cuota2;
                resultado = (cantidad * cuota1 - cantidad2) - cantidad;
            } else if (tipo === 2) {
                let gananciaFavor = cantidad * (cuota1 - 1);
                cantidad2 = (cantidad * cuota1) / (cuota2 - comision);
                riesgo = cantidad2 * (cuota2 - 1);
                resultado = gananciaFavor - riesgo;
            }
        }
        if (clase === 1){ // FreeBet
            if (tipo === 1) {
                cantidad2 = ((cantidad * cuota1) - cantidad) / cuota2
                resultado = ((cantidad * cuota1) - cantidad - cantidad2);
            } else if (tipo === 2) {
                let gananciaFavor = cantidad * (cuota1 - 1); 
                cantidad2 = gananciaFavor / (cuota2 - comision); 
                riesgo = cantidad2 * (cuota2 - 1); 
                resultado = gananciaFavor - riesgo; 
            }
        }
        if (clase === 2){ // Reembolso
            if (tipo === 1) {
                cantidad2 = (((cantidad * cuota1) -  reem ) / cuota2)
                resultado = (cantidad * cuota1 - cantidad2) - cantidad;
            } else if (tipo === 2) { 
                let gananciaFavor = cantidad * (cuota1 - 1);
                cantidad2  = ((cantidad * cuota1) - reem) / (cuota2 - comision)
                riesgo = cantidad2 * (cuota2 - 1);
                resultado = gananciaFavor - riesgo;
            }
        }
        if (clase === 3){ // Bono
            if (tipo === 1) {
                cantidad2 = cantidad * cuota1 / cuota2;
                resultado = (cantidad * cuota1 - cantidad2);
            } else if (tipo === 2) { // Pendiente
                let gananciaFavor = cantidad * cuota1 ;
                cantidad2 = ((cantidad * cuota1)) / (cuota2 - comision);
                riesgo = cantidad2 * (cuota2 - 1);
                resultado = gananciaFavor - riesgo;
            }
        }
    }
    return [resultado.toFixed(2), cantidad2.toFixed(2), riesgo.toFixed(2)];
}

const Calculator = () => {
    const { userId } = useContext(UserContext); // Accede al contexto directamente

    const [cantidad, setCantidad] = useState('');
    const [cantidad2, setCantidad2] = useState('');
    const [cuota1, setCuota1] = useState('');
    const [cuota2, setCuota2] = useState('');
    const [comision, setComision] = useState(0.02);
    const [riesgo, setRiesgo ] = useState(0);
    const [resultado, setResultado] = useState(0);
    const [tipo, setTipo] = useState(1);   
    const [clase, setClase] = useState(0); 
    const [reem, setReem] = useState('');
    
    useEffect(() => {
        const [res, cant2, riesgo] = calcularResultado(parseFloat(cantidad) || 0, parseFloat(cuota1) || 0, parseFloat(cuota2) || 0, tipo, parseFloat(comision) || 0, parseFloat(clase) || 0, parseFloat(reem) || 0);
        setResultado(res);
        setCantidad2(cant2);
        setRiesgo(riesgo);
        console.log(reem);
    }, [cantidad, cuota1, cuota2, tipo, comision, clase, reem]);
    
    const resultadoClass = resultado >= 0 ? 'result positive' : 'result negative';

    return (
        <div>
            <Navbar />
            {userId === null ? (
             <div>
             <div className="login-message">
               <h2>Inicia sesión para acceder al contenido</h2>
             </div>
           </div>
            ) : (
            <div className="calculator-container">
                <h1>Calculadora de Apuestas</h1>
                <p>Introduce tus cuotas para calcular el resultado:</p>

                <div className='input-container'>
                    <select value={tipo} onChange={(e) => setTipo(parseInt(e.target.value))}>   
                        <option value="1">A Favor</option>
                        <option value="2">En Contra</option>
                    </select>
                    <select value={clase} onChange={(e) => setClase(parseInt(e.target.value))}>   
                        <option value="0">Dinero Real</option>
                        <option value="1">Apuesta Gratis</option>
                        <option value="2">Reembolso</option>
                        <option value="3">Bono</option>
                    </select>
                    <input 
                        type="number" 
                        placeholder="Cantidad" 
                        value={cantidad} 
                        onChange={(e) => setCantidad(e.target.value)}
                    />
                    {clase === 2 ? (
                        <input
                        type="number"
                        placeholder="Reembolso"
                        value={reem}
                        onChange={(e) => setReem(e.target.value)}
                        />
                    ) : null
                    }
                    <input 
                        type="number" 
                        placeholder="Cuota 1"  
                        value={cuota1} 
                        onChange={(e) => setCuota1(e.target.value)}
                    />  
                    <input 
                        type="number" 
                        placeholder="Cuota 2" 
                        value={cuota2} 
                        onChange={(e) => setCuota2(e.target.value)}
                    />
                    {tipo === 2 ? (
                    <input
                    type="number" 
                    placeholder="Comisión%" 
                    value={comision} 
                    onChange={(e) => setComision(e.target.value)}
                    /> ) : null} 
                </div>
                
                <div className={resultadoClass}>
                    <div className='apuesta-container'>
                        <p>Apuesta a favor 1: {cantidad} a cuota {cuota1}</p>
                        <button className='small-copy-btn' onClick={() => copyToClipboard(cantidad)}>Copiar</button>
                    </div>

                    {tipo === 2 ? 
                    <div>
                        <div className='apuesta-container'>
                            <p>Apuesta en contra 2: {cantidad2} a cuota {cuota2}</p>
                            <button className='small-copy-btn' onClick={() => copyToClipboard(cantidad2)}>Copiar</button>
                        </div>
                        <p>Riesgo: {riesgo}</p>
                    </div>
                    : 
                    <div className='apuesta-container'>
                        <p>Apuesta a favor 2: {cantidad2} a cuota {cuota2}</p>
                        <button className='small-copy-btn' onClick={() => copyToClipboard(cantidad2)}>Copiar</button>
                    </div>
                    }

                    <p>Ganancia Potencial:</p>
                    <p className='benefit'> {resultado} € </p>
                    <p className='rating'>Rating: {(Math.abs(resultado) * 100) / cantidad}%</p>
                </div>
            </div>
             )}
        </div>
    );
}

export default Calculator;
