import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../components/Navbar.js';
import { UserContext } from '../components/UserContext.js';
import '../css/calculator.css';

const Oddsmatcher = () => { 
    return (
        <div>
            <Navbar />
            <h1>Oddsmatcher goes here!</h1>
        </div>
    );
}

export default Oddsmatcher;