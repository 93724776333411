import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../components/UserContext.js';
import '../css/navbar.css';

const Navbar = () => {
  const { userId } = useContext(UserContext); // Accede directamente al contexto
  const [dropdownOpen, setDropdownOpen] = useState(false); // Estado para manejar el dropdown

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const renderContent = () => {
    if (userId === 1) {
      return null;
    } else if (userId === null || userId === undefined) {
      return (
        <Link className="login-button" to="/signIn">Iniciar Sesión</Link>
      );
    } else {
      return (
        <div className="user-info">
          <Link className="account-button" to="/account">Mi cuenta</Link>
        </div>
      );
    }
  };

  return (
    <nav className="navbar-container">
      <div className="nav-items">
        <Link to="/" className="nav-item">Inicio</Link>
        <Link to="/mbetting" className="nav-item">Matched Betting</Link>
        <Link to="/arbbetting" className="nav-item">Arbitraje</Link>
        {/* Dropdown */}
        <div className="nav-item dropdown" onClick={toggleDropdown}>
          <span>Herramientas</span>
          <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
            <Link to="/tools/calc" className="dropdown-item">Calculadora Cuotas</Link>
            <Link to="/tools/odds" className="dropdown-item">Oddsmatcher</Link>
          </div>
        </div>
        <Link to="/aboutus" className="nav-item">¿Como funciona?</Link>
      </div>
      {renderContent()}
    </nav>
  );
};

export default Navbar;
