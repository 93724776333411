import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import Inicio from './pages/Inicio.js';
import MBPage from './pages/MBPage.js';
import ArbPage from './pages/ArbPage.js';
import AboutUs from './pages/AboutUs.js';
import PromoDetail from './pages/PromoDetail.js';
import Register from './pages/Register.js';
import SignIn from './pages/SignIn.js';
import Account from './pages/Account.js';
import Calculator from './pages/Calculator.js';
import Oddsmatcher from './pages/Oddsmatcher.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './components/UserContext.js';  // Importa el UserProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>  {/* Envuelve el Router con el UserProvider */}
      <Router>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/mbetting" element={<MBPage/>} /> 
          <Route path="/arbbetting" element={<ArbPage/>} /> 
          <Route path="/aboutus" element={<AboutUs/>} /> 
          <Route path="/mbetting/promo/:promoId" element={<PromoDetail />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/account" element={<Account />} />
          <Route path="/tools/calc" element={<Calculator />} />
          <Route path="/tools/odds" element={<Oddsmatcher />} />
        </Routes>
      </Router>
    </UserProvider>
  </React.StrictMode>
);