import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../components/Navbar.js';
import { useParams } from 'react-router-dom';
import { UserContext } from '../components/UserContext.js';
import '../css/promoDetail.css';

const PromoDetail = () => {
  const { promoId } = useParams(); // Obtén el ID de la promoción de los parámetros de la URL
  const [promo, setPromo] = useState(null);
  const [bookie, setBookie] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga
  const [error, setError] = useState(null); // Estado para manejar errores
  const { userId } = useContext(UserContext);

  useEffect(() => {
    const fetchPromoDetails = async () => {
      try {
        const promoResponse = await fetch(`https://panda-bet-back.vercel.app/promos/${promoId}`);
        if (!promoResponse.ok) {
          throw new Error('Error al obtener los detalles de la promoción');
        }
        const promoData = await promoResponse.json();
        
        const bookieResponse = await fetch(`https://panda-bet-back.vercel.app/bookies/${promoData.bookie}`);
        if (!bookieResponse.ok) {
          throw new Error('Error al obtener los detalles del bookie');
        }
        const bookieData = await bookieResponse.json();
        
        setPromo(promoData);
        setBookie(bookieData);
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false); // Termina el estado de carga
      }
    };

    fetchPromoDetails();
  }, [promoId]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (error) {
    return <p>{`Error: ${error}`}</p>;
  }

  if (!promo || !bookie) {
    return <p>No se encontraron detalles.</p>;
  }

  return (
    <div className="promo-container">
      <Navbar/>
      {userId !== null ? (
         <> 
      <h2>{promo.name}</h2>
      <img className="promo-bookie-image" src={bookie.image} alt={bookie.name} />
      <p>Ganancia estimada: {promo.earning}€</p>
      <div className='description-container'>
        <p>{promo.description}</p>      
      </div>
      {/* Otros detalles de la promoción */}
      </> 
       ) : ( 
           <div>
             <div className="login-message">
               <h2>Inicia sesión para acceder al contenido</h2>
             </div>
           </div>
       )}
    </div>
  );
};

export default PromoDetail;